<template>
  <v-dialog v-model="dialog" persistent max-width="450px">
    <validation-observer v-slot="{ handleSubmit }">
      <v-card :disabled="loading" :loading="loading">
        <v-card-title>
          {{ $t("hr.travel_request.travel_approval") }}
          <v-spacer />
          <v-btn depressed icon @click="onClickCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-3" v-if="data">
          <v-row>
            <v-col cols="12">
              <span
                v-html="
                  $t('hr.travel_request.message', [
                    `${data.travel.employee.firstName} ${data.travel.employee.lastName}`,
                    $helpers.formattedDate(data.travel.beginDate),
                    $helpers.formattedDate(data.travel.endDate)
                  ])
                "
              />
            </v-col>
            <v-col cols="12">
              <validation-provider :name="$t('global.note')" rules="max:500" v-slot="{ errors }">
                <v-textarea
                  v-model.trim="travelApprovalDTO.note"
                  rows="1"
                  name="note"
                  counter="500"
                  :label="$t('global.note')"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <v-radio-group row mandatory v-model="travelApprovalDTO.approved">
                <v-radio color="primary" :value="false" :label="$t('leave.request.denied')" />
                <v-radio color="primary" :value="true" :label="$t('leave.request.approved')" />
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="dialog-footer">
          <v-spacer></v-spacer>
          <v-btn depressed @click.stop="handleSubmit(onClickSave)">{{ $t("buttons.save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
  export default {
    name: "ApprovalDialog",
    props: {
      value: Boolean,
      item: Object
    },
    data: () => ({
      dialog: false,
      loading: false,
      data: null,
      travelApprovalDTO: {
        approved: false,
        note: null
      }
    }),
    watch: {
      value: {
        handler(status) {
          this.dialog = status;
        },
        immediate: true
      },
      item: {
        handler(data) {
          this.data = data;
        },
        deep: true
      }
    },
    methods: {
      onClickCancel() {
        this.travelApprovalDTO.approved = null;
        this.travelApprovalDTO.note = null;
        this.data = null;
        this.$emit("close");
      },
      onClickSave() {
        this.loading = true;
        this.$api.travelService
          .approve(this.data.id, this.travelApprovalDTO)
          .then(data => {
            if (data) {
              this.$emit("success");
              this.travelApprovalDTO.note = null;
              this.travelApprovalDTO.approved = false;
              this.data = null;
            }
          })
          .catch(e => this.$helpers.showNotification(e.message))
          .finally(() => (this.loading = false));
      }
    }
  };
</script>

<style scoped></style>
